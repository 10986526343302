import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGPublication from '../../images/new/publications.jpeg'
import { PUBLICATIONS } from '../../components/constant'
const Structure = () => {
  return (
    <div>
      <Layout>
        <SEO title="Publications" />
        <PageStructure
          title="Publications"
          desc="TESTIG"
          mode={PUBLICATIONS}
          image={IMGPublication}
        />
      </Layout>
    </div>
  )
}

export default Structure
